<section class="testimonial">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 p-0">
                <div class="testimonial__text">
                   <h3>   Welcome to Deal Shop, your one-stop-shop for premium corporate gifting solutions. Our mission is to provide exceptional gifting options that exceed your expectations and help you create memorable experiences for your clients, customers, and employees. </h3>
                    <p>“At Deal Shop, we understand that corporate gifting is more than just a gesture; it's an opportunity to build strong and lasting relationships with the people who matter most to your business. That's why we offer a wide range of thoughtful and personalized gift options that are sure to impress.”
                    </p>

                  

<p>Our extensive product range includes premium gift baskets, custom branding options, personalized items, and more. We work with ethical suppliers who share our commitment to sustainability and social responsibility, ensuring that every gift we provide is not only beautiful but also ethical and eco-friendly.
</p>
<p>
We understand that every client is unique, and that's why we offer personalized support throughout the gifting process. Our team of experts is available to help you choose the perfect gift, customize your branding, and ensure timely delivery to your desired location.
</p>

<p>
At Deal Shop, we believe in giving back to the community, and that's why we donate a portion of our profits to charitable causes that support education and environmental sustainability.
</p>

<p>
Browse our extensive product range today and discover the perfect gift for any occasion. Whether you need to send gifts to multiple locations or require custom branding on your gifts, we have the expertise and resources to make it happen.
Thank you for choosing Deal Shop for your corporate gifting needs. We look forward to helping you create memorable and meaningful gifts that strengthen your relationships and build your brand.
</p>

                  
                </div>
            </div>
            <div class="col-lg-4 p-0">
                <div class="testimonial__pic set-bg" data-setbg="assets/img/hero-img.svg" style="background-image: url(&quot;assets/img/hero-img.svg&quot;);"></div>
            </div>
        </div>
    </div>
</section>