
<section class="breadcrumb-option">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb__text">
                    <h4>Mission</h4>
                    <div class="breadcrumb__links">
                        <a href="#home">Home</a>
                        <span>Mission</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


  <main id="main">

    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container">

        <div class="row justify-content-between">
         
          <div class="col-lg-12 pt-5 pt-lg-0">
            <h3 data-aos="fade-up">Our Mission</h3>
            <br>
            <p data-aos="fade-up" data-aos-delay="100">
                Our mission at Deal Shop is to provide exceptional gifting solutions that exceed your expectations. We understand that corporate gifting is a reflection of your brand and your values, and that's why we take every opportunity to create unique and memorable gifts that align with your vision.
                <br>   <br>
                Our team of experts is constantly exploring new trends and innovations in the gifting industry to provide you with the latest and greatest options for your corporate gifting needs. From premium gift baskets to personalized items and everything in between, we have something for everyone.
                <br>   <br>
                We pride ourselves on our commitment to sustainability and social responsibility, and we partner with ethical suppliers who share our values. We believe that giving back to the community is an integral part of our business, and that's why we donate a portion of our profits to charitable causes that support education and environmental sustainability.
                <br>   <br>
                At Deal Shop, we value transparency and open communication, and we strive to build long-term relationships with each of our clients. We are always available to answer your questions and provide support throughout the gifting process, from choosing the perfect gift to tracking your shipment and ensuring timely delivery.
                <br>   <br>
                Thank you for choosing Deal Shop for your corporate gifting needs. We look forward to working with you to create memorable and meaningful gifts that strengthen your relationships and build your brand.
                
            </p>
            <!-- <div class="row">
              <div class="col-md-6" data-aos="fade-up" data-aos-delay="100">
                <i class="bx bx-receipt"></i>
                <h4>Corporis voluptates sit</h4>
                <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
              </div>
              <div class="col-md-6" data-aos="fade-up" data-aos-delay="200">
                <i class="bx bx-cube-alt"></i>
                <h4>Ullamco laboris nisi</h4>
                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
              </div>
            </div> -->
          </div>
        </div>

      </div>
    </section><!-- End About Section -->

    <!-- ======= Services Section ======= -->
   

   




 

  </main><!-- End #main -->


   