   
   <section class="breadcrumb-option">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb__text">
                    <h4>Contact us</h4>
                    <div class="breadcrumb__links">
                        <a href="#home">Home</a>
                        <span>Contact us</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

   <!-- ======= Contact Us Section ======= -->
    <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
  
          <div class="section-title">
            <h2>Contact Us</h2>
            <p>Contact us the get started</p>
          </div>
  
          <div class="row">
  
            <div class="col-lg-5 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
              <div class="info">
                <div class="address">
                  <i class="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>B101, 1st Floor, Pocket 6, Best Arcade Building, Sector 12, Dwarka, New Delhi 110075</p>
                </div>
  
                <div class="email">
                  <i class="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>dealshop2705@gmail.com
                </p>
                </div>
  
                <div class="phone">
                  <i class="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 99990 72593</p>
                </div>
  
               </div>
  
            </div>
  
            <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.2599399915994!2d77.04021837927208!3d28.591977723252647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1adb2cb40bfb%3A0x37b6dbb5e1728c20!2sPocket%206%2C%20VARDHMAN%20PRASHAD%20PLAZA%2C%20Sector%207%20Extension%2C%20Pocket%204%2C%20Sector%2012%20Dwarka%2C%20Dwarka%2C%20Delhi%2C%20110075!5e0!3m2!1sen!2sin!4v1708057265470!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            
            </div>
  
          </div>
  
        </div>
      </section><!-- End Contact Us Section -->